import { Component, BaseComponent, namespace } from '@zento-lib/components';
import { IVSFStoreUI, KEY as UIKey } from 'theme/@types/vsf/stores/ui';
import { StoreConfig } from 'theme/stores/storeConfig/storeConfig';
import { NewsletterStore } from 'theme/stores/newsletter/newsletter';
import { SearchState } from 'theme/stores/search/search';

import type { IOverlay } from './Overlay.d';
import style from './style.scss';

const uiStore = namespace<IVSFStoreUI>(UIKey);

/**
 * Overlay
 *
 * An additional layer on top of the current state of the interface
 */
@Component({})
export class Overlay extends BaseComponent<IOverlay, unknown> {
  @uiStore.State('overlay')
  public overlayActive: IVSFStoreUI['state']['overlay'];

  @uiStore.State('submenu')
  public submenu: IVSFStoreUI['state']['submenu'];

  protected storeConfigData = new StoreConfig();
  protected newsletterStore = new NewsletterStore();
  protected activeSearch = new SearchState();

  /**
   * Return overlay store state
   */
  get isVisible() {
    return this.overlayActive;
  }

  /**
   * Handler for closing opened sidebars, modals and/or overlay
   */
  close() {
    this.$store.commit('ui/setOverlay', false);
    this.$store.commit('ui/setMicrocart', false);
    this.$store.commit('ui/setWishlist', false);
    this.$store.commit('ui/setSidebar', false);
    this.$store.commit('myAccount/setMyAccount', false);
    this.$store.commit('myAccount/setModalActive', false);
    this.$store.commit('myAccount/setDrawerActive', false);
    this.$store.commit('myFilters/setDrawerFilter', false);
    this.$store.commit('ui/setProfileDrawer', false);
    this.newsletterStore.drawerState = false;
    this.activeSearch.searchState = false;
    document.body.style.overflow = 'visible';

    if (!this.activeSearch.searchState) {
      this.broadcast('search-state-closed');
    }

    // Reset menu to first level
    this.submenu.path = [];
  }

  render() {
    return (
      <div
        class={{
          [style.overlay]: true,
          [style.overlayOpened]: this.isVisible,
          [style.overlaySearch]: this.activeSearch.searchState,
          [style.overlayNewsletter]:
            this.storeConfigData.storeConfigurations.newsletterFormsPopupEnabled === 1 &&
            // (this.newsletterState.newsletter.newsletter_forms_popup_template === 'drawer' || // TODO: check if this condition is necessary
            //   this.newsletterState.newsletter.newsletter_forms_popup_template === 'pop-up') &&
            this.newsletterStore.drawerState,
          [style.overlayAccount]: this.$store.state.ui.profileDrawer,
        }}
        data-testId='overlay'
        onClick={this.close}></div>
    );
  }
}
